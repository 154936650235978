<template>
    <div class="pdl-list">
        <PageHeading :breadcrumbs="breadcrumbs" title="PDL Convos" />

        <FullPageLoadingSpinner :show="$wait.is($options.LoadingFlag.ConvoGet)" />

        <div class="m-b-2 pdl-list__search">
            <Input
                id="search"
                ref="searchTerm"
                v-model="searchTerm"
                type="text"
                name="search"
                label="Search PDL convo"
                placeholder="Search PDL convo"
                :has-margin-bottom="false"
                :has-margin-top="true"
                :show-clear-icon="true"
                class="w-25"
                default-icon="magnifying-glass"
                @input="handleSearch"
            />
        </div>

        <Datatable
            :data="convoListView.convoList"
            :columns="[
                {
                    value: 'name',
                    header: 'PDL CONVO',
                    type: 'text',
                    width: '50%',
                },
                {
                    value: 'createdAt',
                    header: 'CREATED ON',
                    type: 'date',
                    width: '30%',
                    format: config.dateFormat,
                },
                {
                    value: 'country',
                    header: 'YGD COUNTRY',
                    type: 'slot',
                    width: '15%',
                },
                {
                    value: 'actions',
                    class: 'table-row-actions',
                    header: '',
                    type: 'slot',
                    width: '5%',
                },
            ]"
        >
            <template v-slot:country="{ item }">
                {{ getCountryNameByCode(item.ygdCountry) }}
            </template>

            <template v-slot:actions="{ item }">
                <TableRowActions
                    :menu-actions="[
                        {
                            label: 'Delete Convo',
                            action: 'delete-convo',
                            icon: 'delete-full',
                        },
                    ]"
                    @action="$event === 'delete-convo' && handleDeleteConvo(item)"
                />
            </template>
        </Datatable>

        <Dialog
            show-confirm-button
            confirm-button-text="Delete convo"
            close-button-text="Cancel"
            :is-dialog-visible="dialogIsVisible"
            @onClose="handleDialogCancel"
            @onClickOutside="handleDialogCancel"
            @closeOnEscapeEvent="handleDialogCancel"
            @confirmButtonOnClick="handleDialogConfirm"
        >
            <template #header>Deleting "{{ convoToBeDeleted.name }}"</template>
            <template #body>Are you sure you want to delete this convo?</template>
        </Dialog>

        <Paging
            :total="convoListView.convoList.total"
            :size="convoListView.convoList.size"
            :page="convoListView.convoList.page"
            @pageSelected="handlePageChange"
        />

        <Stepper
            show-next-button
            next-button-help=""
            next-button-text="New PDL Convo"
            :nav-is-opened="navIsOpened"
            :is-loading="false"
            @nextButtonOnClick="$router.push({ name: 'pdl-mode' })"
        />
    </div>
</template>

<script>
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import { ConvoTypes, LoadingFlag } from '@/store/enums/index.ts';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import TableRowActions from '@/components/TableRowActions';
import PageHeading from '@/components/ui/PageHeading';
import Datatable from '@/components/Datatable';
import { mapActions, mapGetters } from 'vuex';
import countryList from '@/json/countries.ts';
import Stepper from '@/components/ui/Stepper';
import Input from '@/components/forms/Input';
import Dialog from '@/components/ui/Dialog';
import { ContentClient } from '@/api/content';
import Paging from '@/components/Paging';
import config from '@/config';

export default {
    name: 'PDLList',
    components: {
        FullPageLoadingSpinner,
        TableRowActions,
        PageHeading,
        Datatable,
        Stepper,
        Paging,
        Dialog,
        Input,
    },
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            config,
            convoToBeDeleted: null,
            dialogIsVisible: false,
            searchTerm: '',
            breadcrumbs: [new Breadcrumb('PDL convos', { name: 'pdl-list' })],
        };
    },
    computed: {
        ...mapGetters('convoList', ['convoListView']),
    },
    created() {
        this.$options.LoadingFlag = LoadingFlag;
        this.getConvos({
            channelId: -1,
            convoTypes: [ConvoTypes.PDLConvo],
            redirectName: 'pdl-edit',
        });
    },
    methods: {
        ...mapActions('convoList', ['getConvos']),
        handlePageChange(event, page) {
            this.getConvos({
                page,
                channelId: -1,
                convoTypes: [ConvoTypes.PDLConvo],
                redirectName: 'pdl-edit',
            });
        },
        handleSearch() {
            this.getConvos({
                channelId: -1,
                convoTypes: [ConvoTypes.PDLConvo],
                redirectName: 'pdl-edit',
                q: this.searchTerm,
                page: this.convoListView.convoList.page,
            });
        },
        handleDialogCancel() {
            this.convoToBeDeleted = null;
            this.hideDialog();
        },
        handleDialogConfirm() {
            this.deleteConvo(this.convoToBeDeleted);
            this.hideDialog();
        },
        handleDeleteConvo(convo) {
            if (convo && convo.id) {
                this.convoToBeDeleted = convo;
                this.showDialog();
            }
        },
        async deleteConvo(convo) {
            await ContentClient.deleteConvo(convo.id);
            this.getConvos({
                channelId: -1,
                convoTypes: [ConvoTypes.PDLConvo],
                redirectName: 'pdl-edit',
            });
        },
        showDialog() {
            this.dialogIsVisible = true;
        },
        hideDialog() {
            this.dialogIsVisible = false;
        },
        getCountryNameByCode(iso2) {
            const country = countryList.find((c) => c.iso2 === iso2);
            if (!country) {
                return '';
            }
            return country.name;
        },
    },
};
</script>
